import PropTypes from "prop-types";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Progress, Tooltip } from "reactstrap";
import { useMutation } from "@tanstack/react-query";
import { ORDER_STATUSES } from "../constants/order";
import usePermissions from "../../../../hooks/usePermissions";
import { TrafficOrderService } from "../../../../services/traffic_order.service";
import UserListAvatar from "../../../../components/UI/userListAvatar";

const CardOrder = (props: any) => {

  const { hasPermission } = usePermissions();
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const {
    data,
    toggleAssignOrder,
    toggleAssignIntegra,
    toggleInfoOrder,
    toggleUpdate,
    toggleChangeStatus,
    activeTabId,
  } = props;

  const capa_day =
    data?.current_capas?.capa_day ||
    data?.waiting_capas?.capa_day ||
    data?.not_active_capas?.capa_day ||
    0;

  const order_status =
    data?.current_capas?.status ||
    data?.waiting_capas?.status ||
    data?.capa_dates?.at(-1)?.status ||
    null;

  const order_day =
    data?.current_capas?.date ||
    data?.waiting_capas?.date ||
    data?.not_active_capas?.date ||
    null;

  const status = ORDER_STATUSES.find((status) => status.value === order_status);

  const { mutate: pinnedTrafficOrder, isPending } = useMutation({
    mutationFn: () => TrafficOrderService.pinnedTrafficOrder(data.id),
    onError: (error) => {
      console.error("Error pinning traffic order:", error);
    },
  });

  const formatTime = (time: string) => {
    if (!time) return "";
    const [hours, minutes] = time.split(":"); 
    return `${hours}:${minutes}`; 
  };

  const handlePinnedTrafficOrder = () => {
    if (!isPending) pinnedTrafficOrder();
  };

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      <Col style={{ flex: "0 0 20%", maxWidth: "20%" }} md="3" sm="4">
        <Card className="border border-2 text-center">
          <CardBody>
            <div
              className="btn-group justify-content-between mb-2"
              style={{ display: "flex" }}
              role="group"
            >
              <div className={"text-start"}>
                {/*{
                  //hasPermission("delete-country-box") &&
                  <Link className="text-danger" to="#">
                    <i
                      className="mdi mdi-delete font-size-12"
                      id="deletetooltip"
                      onClick={() => {}}
                    />
                  </Link>
                }*/}
              </div>
              <div className={"text-end"}>
                {hasPermission("traffic-order-pinned") && activeTabId != 3 && (
                  <Link
                    className={`mdi ${
                      !data.is_pinned ? "text-dark" : "text-info"
                    } `}
                    to="#"
                  >
                    <i
                      onClick={
                        !isPending
                          ? () => handlePinnedTrafficOrder()
                          : undefined
                      }
                      className={`mdi ${
                        !data.is_pinned ? "mdi-pin-off" : "mdi-pin "
                      } font-size-12`}
                    />
                  </Link>
                )}
              </div>
            </div>

            <h5 className="font-size-16 mb-1">{data?.broker?.name}</h5>

            <>
              <div style={{ position: "relative", width: "100%" }}>
                <Progress
                  striped
                  max={data?.daily_cap || 0}
                  value={capa_day}
                  className="my-1"
                  color={"success"}
                  style={{ height: "20px", backgroundColor: "#B9BBB6" }}
                >
                  <span
                    style={{
                      position: "absolute",
                      width: "100%",
                      textAlign: "center",
                      color: "white",
                      lineHeight: "20px",
                    }}
                  >
                    {capa_day}/{data?.daily_cap || 0}
                  </span>
                </Progress>
              </div>
            </>

            <div className={"font-size-12"}>
              {data.countries.map((i: any) => {
                return <span key={i.id}>{i.iso} </span>;
              })}
              {data.country_box.map((i: any) => {
                return <span key={i.id}>{i.name} </span>;
              })}
            </div>

            <div style={{ cursor: "pointer" }}>
              <span
                className={`badge ${status?.colorClass || "bg-secondary"}`}
                onClick={() => toggleChangeStatus(data.id)}
              >
                <i
                  className={`bx ${
                    status?.iconClass || "bx-info-circle"
                  } font-size-16 align-middle me-2`}
                />
                {status?.label || "Unknown"}
              </span>
            </div>

            <div>
              <span
                className={`badge ${
                  data.broker_id ? "bg-success" : "bg-danger"
                }`}
              >
                <i
                  className={`bx ${
                    data.broker_id ? "bx-check-double" : "bx-block"
                  } font-size-16 align-middle me-2`}
                />
                {data.broker_id ? "Integrated" : "Not Integrated"}
              </span>
            </div>
            {(data?.from_time || data?.to_time) && (
              <div style={{ fontSize: "14px", fontWeight: "bold", marginTop: "10px" }}>
                {data?.from_time && (
                  <span>From: {formatTime(data.from_time)}</span>
                )}
                {data?.to_time && <span> To: {formatTime(data.to_time)}</span>}
              </div>
            )}
            {/* {data?.responsible_users && <div className="mt-2">
              <UserListAvatar responsible_users={data.responsible_users}/>
            </div>} */}

            <div className="font-size-14">Pay method: {data?.pay_method_label}</div>

            <div className="font-size-14">Funnel name: {data?.funnel_name}</div>

            <div className="font-size-10" style={{ fontWeight: "bold" }}>
              Date{data?.capa_dates?.length > 0 && "s"}: {order_day}{" "}
              {data?.capa_dates?.length > 0 && (
                <span>
                  <i
                    className="mdi mdi-calendar-blank-multiple font-size-16 text-primary"
                    id={"Tooltip-" + data.id}
                    style={{ cursor: "pointer" }}
                  />
                  <Tooltip
                    placement="bottom"
                    isOpen={tooltipOpen}
                    target={"Tooltip-" + data.id}
                    toggle={toggle}
                    fade={false}
                    delay={{ show: 100, hide: 150 }}
                    //transition={{ timeout: 150 }}
                  >
                    {data?.capa_dates?.map((item: any) => item.date).join(", ")}
                  </Tooltip>
                </span>
              )}
            </div>
          </CardBody>

          <div className="btn-group" role="group">
            {hasPermission("assign-traffic-order") && (
              <button
                type="button"
                className="btn btn-outline-light text-truncate"
                title="Assign"
                onClick={() => toggleAssignOrder(data.id)}
              >
                <i className="mdi mdi-plus-circle font-size-14" />
              </button>
            )}
            {hasPermission("view-info-traffic-order") && (
              <button
                type="button"
                onClick={() => toggleInfoOrder(data.id)}
                className="btn btn-outline-light text-truncate"
                title="Info"
              >
                <i className="mdi mdi-information font-size-14" />
              </button>
            )}
            {hasPermission("edit-traffic-order") && (
              <button
                type="button"
                onClick={() => toggleUpdate(data.id)}
                className="btn btn-outline-light text-truncate"
                title="Edit"
              >
                <i className="mdi mdi-pencil font-size-14" />
              </button>
            )}
            {hasPermission("integrated-traffic-order") && (
              <button
                type="button"
                onClick={() => toggleAssignIntegra(data.id)}
                className="btn btn-outline-light text-truncate"
                title="Integrated"
              >
                <i className="mdi mdi-book-cog font-size-14" />
              </button>
            )}
          </div>
        </Card>
      </Col>
    </>
  );
};

CardOrder.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  onDelete: PropTypes.func,
  toggleAssignOrder: PropTypes.func,
  toggleAssignIntegra: PropTypes.func,
  toggleInfoOrder: PropTypes.func,
  toggleUpdate: PropTypes.func,
  toggleChangeStatus: PropTypes.func,
  activeTabId: PropTypes.string,
};

export default CardOrder;
