import React, { useEffect, useState, useCallback } from "react";
import { Button, Card, CardBody, Row, Col, Input } from "reactstrap";
import NoSearchResult from "../../../../components/Common/NoSearchResult";
import CardOrder from "./CardOrder";
import Loader from "../../../../components/UI/loader";

interface TabPaneContentProps {
  activeTabId: string;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  trafficOrders: any;
  toggleModal: (key: string, id?: string | null, date?: string | null) => void;
  customActions?: {
    toggleAssignOrder?: (id: any) => void;
    toggleAssignIntegra?: (id: any) => void;
    toggleInfoOrder?: (id: any) => void;
    toggleUpdate?: (id: any) => void;
    toggleChangeStatus?: (id: any, date?: any) => void;
    loadMore?: () => void;
  };
}

const TabPaneContent: React.FC<TabPaneContentProps> = ({
                                                         activeTabId,
                                                         isLoading,
                                                         isError,
                                                         isSuccess,
                                                         trafficOrders,
                                                         toggleModal,
                                                         customActions = {},
                                                       }) => {

  return (
    <Card xl="3">
      <CardBody>
        {isLoading ? (
          <Loader />
        ) : isError ? (
          <NoSearchResult text="Error loading data" />
        ) : (
          isSuccess && (
            <Row>
              {!trafficOrders?.data.length && <NoSearchResult text="No traffic orders available" />}
              {trafficOrders?.data.map((order: any, idx: number) => (
                <CardOrder
                  key={`card_${idx}`}
                  data={order}
                  toggleAssignOrder={
                    customActions.toggleAssignOrder || ((id) => toggleModal("isAssignOrder", id))
                  }
                  toggleAssignIntegra={
                    customActions.toggleAssignIntegra || ((id) => toggleModal("isAssignIntegra", id))
                  }
                  toggleInfoOrder={
                    customActions.toggleInfoOrder || ((id) => toggleModal("isInfoOrder", id))
                  }
                  toggleUpdate={
                    customActions.toggleUpdate || ((id) => toggleModal("isUpdate", id))
                  }
                  toggleChangeStatus={
                    customActions.toggleChangeStatus ||
                    ((id) => toggleModal("isChangeStatus", id, order?.current_capas?.date))
                  }
                  activeTabId={activeTabId}
                />
              ))}

              {trafficOrders?.pagination?.next_page && (
                <div className="text-center my-3">
                  <Button color="primary" onClick={customActions.loadMore} disabled={false}>
                    {trafficOrders?.pagination?.isLoading ? "Loading..." : "Load More"}
                  </Button>
                </div>
              )}
            </Row>
          )
        )}
      </CardBody>
    </Card>
  );
};

export default TabPaneContent;